/**
 * @file MyOrderDetail.tsx
 * @author chad / chad@o2pluss.com
 * @description 내 오더 상세 페이지
 */

import {
  useCurrentDelivery,
  useRouteDetail,
  useRouteInfo,
} from '@recoil/routeInfo';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '@hooks/useModal';
import styled from 'styled-components';
import LocationDeeplinkButton from '@components/molecules/LocationDeepLinkBtn';

import Button from '@components/atoms/Button';
import HeaderBack from '@components/molecules/HeaderBack';
import { BsArrow90DegRight } from 'react-icons/bs';
import { IoMdCall } from 'react-icons/io';
import { FaCamera } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';

export default function MyOrderDetail() {
  const { routeInfo, setRouteInfo } = useRouteInfo();
  const { routeDetail } = useRouteDetail();
  const { setCurrentDeliveryAddress } = useCurrentDelivery();
  const { showModal } = useModal();

  const navigate = useNavigate();

  const imageRef = useRef<HTMLInputElement>(null);
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);

  /** 배송상태 업데이트 */
  const updateState = () => {
    const updateIsDelivery = routeInfo.routeInfo.map((info) => {
      if (info.id === routeDetail?.id) {
        return {
          ...info,
          isDelivery: true,
          imageUrl: imageFile,
        };
      } else {
        return {
          ...info,
        };
      }
    });

    setRouteInfo((prev) => ({ ...prev, routeInfo: updateIsDelivery }));
  };

  /** 전화 걸기 */
  const connectionCall = useCallback(
    (phoneNumber: number | string | undefined) => {
      return (location.href = `tel:${phoneNumber}`);
    },
    [],
  );

  /** 이미지 업로드 */
  const imageUpload = () => {
    if (imageRef.current === null || imageRef.current.files === null) return;

    const file = imageRef.current.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageFile(reader.result);
    };
  };

  /** 운송완료 버튼 클릭 */
  const onClickDeliveryCompleted = () => {
    showModal({
      type: 'confirm',
      title: '운송을 완료하셨나요?',
      message: '완료 이후 운송 상태 변경이 어려워요',
      onOk: () => {
        updateState();
        setCurrentDeliveryAddress((prev) => prev + 1);
        navigate(-1);
      },
    });
  };

  return (
    <>
      {routeDetail?.order_num && (
        <HeaderBack
          title={routeDetail.order_num}
          isDelivery={routeDetail.isDelivery}
        />
      )}

      <div className="pt-4 relative">
        <TableContainer className="w-full mb-4">
          <tbody>
            <tr>
              <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                주소
              </td>
              <td className="p-2">
                <p className="mb-1">
                  {routeDetail?.recieve_address_1}{' '}
                  {routeDetail?.recieve_address_2}
                </p>
                {!routeDetail?.isDelivery && (
                  <LocationDeeplinkButton routeItem={routeDetail} />
                  // <Button
                  //   buttonClassName="bg-state-info text-white text-sm mr-2"
                  //   icon={<BsArrow90DegRight className="mr-1" />}
                  //   text="길 안내"
                  // />
                )}
              </td>
            </tr>
          </tbody>
        </TableContainer>

        {!routeDetail?.isDelivery && (
          <>
            <TableContainer className="w-full mb-4">
              <tbody>
                <tr>
                  <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                    수령인
                  </td>
                  <td className="p-2 text-sm">
                    <p>{routeDetail?.reciever}</p>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                    연락처
                  </td>
                  <td className="p-2">
                    <p className="mb-1 text-sm">
                      {routeDetail?.reciever_contact}
                    </p>
                    <Button
                      buttonClassName="bg-state-success text-white text-sm mr-2"
                      icon={<IoMdCall className="mr-1" size="16px" />}
                      text="전화 연결"
                      onClick={() =>
                        connectionCall(routeDetail?.reciever_contact)
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </TableContainer>
            <TableContainer className="w-full mb-4">
              <tbody>
                <tr>
                  <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                    주문자
                  </td>
                  <td className="p-2 text-sm">
                    <p>{routeDetail?.order_name}</p>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                    연락처
                  </td>
                  <td className="p-2 text-sm">
                    <p className="mb-1">{routeDetail?.order_contact}</p>
                    <Button
                      buttonClassName="bg-state-success text-white text-sm mr-2"
                      icon={<IoMdCall className="mr-1" size="16px" />}
                      text="전화 연결"
                      onClick={() => connectionCall(routeDetail?.order_contact)}
                    />
                  </td>
                </tr>
              </tbody>
            </TableContainer>
          </>
        )}

        <TableContainer className="w-full mb-4">
          <tbody>
            <tr>
              <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                물품정보
              </td>
              <td className="p-2 text-sm">
                <p>{routeDetail?.article}</p>
              </td>
            </tr>
            <tr>
              <td className="p-2 text-center w-[25%] bg-gray-100 text-gray-700 text-sm">
                메모
              </td>
              <td className="p-2 text-sm">
                <p className="mb-1">
                  {routeDetail?.delivery_req ? routeDetail.delivery_req : '-'}
                </p>
              </td>
            </tr>
          </tbody>
        </TableContainer>

        <div className="mb-[100px]">
          <div className="mb-4">
            <p className="text-sm">자료첨부</p>
            <span className="text-xs text-gray-500">인수증, 물품사진 등</span>
          </div>

          <div className="w-[200px] h-[150px] rounded-lg border">
            {routeDetail?.imageUrl || imageFile ? (
              <div className="relative">
                <img
                  className="object-fill w-[200px] h-[150px]"
                  src={routeDetail?.imageUrl || imageFile}
                  alt="자료첨부이미지"
                />
                {!routeDetail?.imageUrl && (
                  <TiDelete
                    className="absolute -top-3 -right-4 bg-white rounded-2xl"
                    size="30px"
                    color="grey"
                    onClick={() => setImageFile(null)}
                  />
                )}
              </div>
            ) : (
              <label
                className="flex items-center justify-center h-full"
                htmlFor="imageUpload"
              >
                <div className="flex flex-col items-center">
                  <FaCamera className="mb-1" />
                  <span className="text-xs text-gray-600">첨부하기</span>
                </div>
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*;capture=camera"
                  hidden
                  ref={imageRef}
                  onChange={imageUpload}
                />
              </label>
            )}
          </div>
        </div>
      </div>

      {!routeDetail?.isDelivery && (
        <div className={`w-full fixed bottom-0 left-0 px-4 pt-4 pb-8 bg-white`}>
          <Button
            buttonClassName={`w-full text-white ${
              imageFile ? '' : 'bg-gray-300'
            }`}
            text="운송 완료"
            size="lg"
            gradation={imageFile ? true : false}
            disabled={imageFile ? false : true}
            onClick={onClickDeliveryCompleted}
          />
        </div>
      )}
    </>
  );
}

const TableContainer = styled.table`
  table,
  td,
  th {
    border: 1px solid #e2e2e2;
    border-collapse: collapse;
  }
`;
