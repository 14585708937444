import NATIVE_MSG_ID, {
  NATIVE_MSG_PREFIX,
  TPostMessage,
} from '@constants/nativeMessageFormat';
import { useEffect } from 'react';

interface IParams {
  key: NATIVE_MSG_ID;
  onMessage?: (data: any) => void;
}

/**
 * webview <--> native message test hooks
 * @param param0
 * @returns
 */
export default function usePostMessage({ key, onMessage }: IParams) {
  //
  useEffect(() => {
    document.addEventListener('message', _onMessage);
    return () => {
      document.removeEventListener('message', _onMessage);
    };
  }, []);

  /**
   *  native -> webview
   */
  const _onMessage = (e: any) => {
    const response = JSON.parse(e.data) as TPostMessage;
    if (response.key === key) {
      onMessage && onMessage(response.data);
    }
  };

  /**
   * webview -> native
   */
  const sendToNative = (data: any) => {
    if (!window.ReactNativeWebView) {
      // alert('디바이스에서만 실행할 수 있습니다.');
      return;
    }
    window.ReactNativeWebView.postMessage(
      NATIVE_MSG_PREFIX +
        JSON.stringify({
          key,
          data,
        }),
    );
  };

  return {
    sendToNative,
  };
}
