/**
 * @file Card.tsx
 * @author chad / chad@o2pluss.com
 * @description 카드 컴포넌트 children 으로 자식 컴포넌트를 받는다.
 */

import React from 'react';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
  cardClassName?: string;
  border?: boolean;
  smallPadding?: boolean;
}

export default function Card({
  children,
  cardClassName,
  border,
  smallPadding,
}: IProps) {
  return (
    <div
      className={classNames(
        `w-full rounded-lg
        ${border ? 'border border-solid' : ''} 
        ${smallPadding ? 'p-3' : 'p-4'}`,
        cardClassName,
      )}
    >
      {children}
    </div>
  );
}
