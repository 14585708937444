/**
 * @file KakaoMap
 * @author chad / chad@o2pluss.com
 * @description 카카오맵
 */

import { useEffect } from 'react';
import classNames from 'classnames';
import { IRouteExcelData } from '@data/receiptRouteInfo';
import { useCurrentDelivery } from '@recoil/routeInfo';
import { userInfoData } from '@data/userInfo';

declare global {
  interface Window {
    kakao: any;
  }
}

interface IProps {
  className?: string;
  children?: React.ReactNode;
  routeInfo?: IRouteExcelData[];
}

export default function KakaoMap({ className, children, routeInfo }: IProps) {
  const { currentDeliveryAddress } = useCurrentDelivery();

  useEffect(() => {
    const mapScript = document.createElement('script');
    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAOMAP_KEY}&autoload=false`;
    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      const { kakao } = window;

      kakao.maps.load(() => {
        /** 지도 생성 */
        const mapContainer = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(33.450701, 126.570667),
          level: 3,
        };
        const map = new kakao.maps.Map(mapContainer, options);

        routeInfo &&
          routeInfo.map((info, index) => {
            const customOverlay = new kakao.maps.CustomOverlay({
              position: new kakao.maps.LatLng(info.y, info.x),
              content: `
              <div
                class="w-[30px] h-[30px] flex items-center justify-center
                ${
                  info.isDelivery
                    ? 'bg-gray-500 text-white'
                    : currentDeliveryAddress === index + 1
                      ? 'bg-primary-text text-white'
                      : 'bg-white border-solid border-2 border-primary-text'
                } rounded-3xl"
              >
                <span class="p-1 text-sm font-bold">
                  ${index + 1}
                </span>
              </div>`,
            });

            customOverlay.setMap(map);
          });
        
        /** 기사 현위치 */
        const riderPosition = new kakao.maps.CustomOverlay({
          position: new kakao.maps.LatLng(
            userInfoData.rider_lat,
            userInfoData.rider_lng,
          ),
          content: `
              <div
                class="flex items-center justify-center w-[20px] h-[20px] border-solid border-2 border-red-500 rounded-3xl"
              >
                <span class="bg-red-500 w-[12px] h-[12px] rounded-3xl" />
              </div>`,
        });
        riderPosition.setMap(map);

        /** 선 연결 */
        const polylinePath: any[] = [];
        routeInfo &&
          routeInfo.map((info) => {
            polylinePath.push(new kakao.maps.LatLng(info.y, info.x));
          });
        const polyline = new kakao.maps.Polyline({
          path: polylinePath,
          strokeWeight: 3,
          strokeColor: 'black',
          strokeStyle: 'dashed',
        });
        polyline.setMap(map);

        /** 지도 범위 재설정 */
        const bounds = new kakao.maps.LatLngBounds();
        routeInfo &&
          routeInfo.map((info) => {
            bounds.extend(new kakao.maps.LatLng(info.y, info.x));
          });
        map.setBounds(bounds);
      });
    };

    mapScript.addEventListener('load', onLoadKakaoMap);
  });

  return (
    <div id="map" className={classNames('w-full h-full', className)}>
      {children}
    </div>
  );
}
