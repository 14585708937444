/**
 * @file Main.tsx
 * @author chad / chad@o2pluss.com
 * @description 메인페이지 (출근하기 페이지)
 */

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userInfoData } from '@data/userInfo';
import { receiptRouteData } from '@data/receiptRouteInfo';
import { useCurrentDelivery, useRouteInfo } from '@recoil/routeInfo';

import HeaderNavigation from '@components/molecules/HeaderNavigation';
import Button from '@components/atoms/Button';
import Card from '@components/atoms/Card';
import { FaCheckCircle } from 'react-icons/fa';
import { useModal } from '@hooks/useModal';

export default function Main() {
  const navigate = useNavigate();
  const { setRouteInfo } = useRouteInfo();
  const { setCurrentDeliveryAddress } = useCurrentDelivery();
  const { showModal } = useModal();

  const [clickedRoute, setClickedRoute] = useState<number | null>(null);

  /** 선택한 경로 정보 가져오기 */
  const getRouteInfo = useCallback(() => {
    const selectedItem = receiptRouteData.filter(
      (data) => data.id === clickedRoute,
    );

    setRouteInfo(selectedItem[0]);
  }, [clickedRoute, setRouteInfo]);

  /** 출근하기 버튼 클릭 */
  const onClickAttendanceBtn = useCallback(() => {
    if (!clickedRoute) {
      showModal({
        title: '경로를 선택해주세요!',
        message: '경로가 선택되어야 출근이 가능해요!',
      });

      return;
    }

    getRouteInfo();
    setCurrentDeliveryAddress(1);
    navigate('my-order');
  }, [getRouteInfo, navigate, setCurrentDeliveryAddress, showModal]);

  return (
    <>
      <HeaderNavigation />
      <div className="pt-12">
        <h6 className="font-bold text-2xl mb-6">
          {userInfoData.name} 님,
          <br />
          오늘도 안전운행 하세요!
        </h6>

        <Card cardClassName="bg-gray-100">
          {receiptRouteData.map((route) => {
            return (
              <Card
                key={route.id}
                cardClassName="bg-white mb-4 cursor-pointer"
                smallPadding
              >
                <div
                  className="flex items-center justify-between"
                  role="presentation"
                  onClick={() => setClickedRoute(route.id)}
                >
                  <div>
                    <p className="text-lg font-bold">{route.routeName}</p>
                    <span className="text-gray-700">{route.vehicleNumber}</span>
                  </div>
                  <FaCheckCircle
                    className={`${
                      clickedRoute === route.id
                        ? 'text-primary-text'
                        : 'text-gray-200'
                    }`}
                    size="24px"
                  />
                </div>
              </Card>
            );
          })}
          <Button
            text="출근하기"
            buttonClassName="text-white w-full"
            size="lg"
            gradation
            onClick={onClickAttendanceBtn}
          />
        </Card>
      </div>
    </>
  );
}
