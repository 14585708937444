/**
 * @file MainTemlate
 * @author chad / chad@o2pluss.com
 * @description 메인 템플릿
 */

import { Outlet } from 'react-router-dom';

export default function MainTemlate() {
  return (
    <div className="min-w-[360px] max-w-[400px] pb-8 px-4 mx-auto">
      <Outlet />
    </div>
  );
}
