/**
 * @file Button.tsx
 * @author chad / chad@o2pluss.com
 * @description 버튼 컴포넌트
 */

import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

type Type = 'submit' | 'reset' | 'button';
type Size = 'md' | 'lg';

interface IProps {
  type?: Type;
  text: string | React.ReactNode;
  buttonClassName?: string;
  disabled?: boolean;
  icon?: React.ReactElement | boolean;
  bold?: boolean;
  chip?: boolean;
  size?: Size;
  setData?: number | string;
  gradation?: boolean;
  grayBorder?: boolean;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
}

export default function Button({
  type = 'button',
  setData,
  buttonClassName,
  text,
  icon,
  disabled,
  bold = true,
  chip,
  size,
  gradation,
  grayBorder,
  onClick,
}: IProps) {
  return (
    <>
      <ButtonContainer
        size={size}
        data-id={setData}
        type={type}
        className={classNames(
          `cursor-pointer px-2
          ${chip ? 'rounded-2xl' : 'rounded-lg'} 
          ${bold ? 'font-bold' : ''}
          ${
            gradation
              ? 'bg-gradient-to-r from-primary-gra-01 to-primary-gra-02 '
              : ''
          },
          ${grayBorder ? 'border-solid border border-gray-400' : ''}`,
          buttonClassName,
        )}
        disabled={disabled}
        onClick={onClick}
      >
        <div className="flex items-center justify-center whitespace-nowrap">
          {icon && <span className="mr-1">{icon}</span>}
          <span>{text}</span>
        </div>
      </ButtonContainer>
    </>
  );
}

const ButtonContainer = styled.button<{ size?: string }>`
  height: ${(props) =>
    props.size === 'md' ? '44px' : props.size === 'lg' ? '56px' : '32px'};
`;
