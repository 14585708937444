import { Routes, Route } from 'react-router-dom';

import MainTemlate from '@components/templates/MainTemlate';
import Main from '@pages/main/Main';
import MyOrder from '@pages/my-order/MyOrder';
import MyOrderDetail from '@pages/my-order/MyOrderDetail';
import Login from '@pages/login/Login';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/main" element={<MainTemlate />}>
        <Route index element={<Main />} />
        <Route path="my-order" element={<MyOrder />} />
        <Route path="my-order/detail" element={<MyOrderDetail />} />
      </Route>
    </Routes>
  );
};

export default Router;
