/**
 * native message id
 * webview, native의 nativeMessageId.tsx 통일 필수
 */

// import { IRouteExcelData } from '@data/receiptRouteInfo';

// 메세지 구문위해 앞에 추가
export const SPLITTER = '//';
export const NATIVE_MSG_PREFIX = '__APP_NATIVE__' + SPLITTER;

export interface TPostMessage {
  key: keyof typeof NATIVE_MSG_ID;
  data: any;
}

export interface ICoord {
  lat: number;
  lng: number;
}

// 메세지 key 목록
const NATIVE_MSG_ID = {
  WEBVIEW_READY: 'WEBVIEW_READY', // 웹뷰, postmessage 준비 완료
  PHONE_NUMBER: 'PHONE_NUMBER', // 전화번호 획득
  LOGIN: 'LOGIN', // login 시
  LOGOUT: 'LOGOUT', // logout 시
  // photo upload
  OPEN_PHOTO_UPLOAD: 'OPEN_PHOTO_UPLOAD', // 사진 업로드
  CLOSE_PHOTO_UPLOAD: 'CLOSE_PHOTO_UPLOAD', // 사진 업로드
  // foreground
  START_FOREGROUND_SERVICE: 'START_FOREGROUND_SERVICE', // 포그라운드 서비스 시작
  STOP_FOREGROUND_SERVICE: 'STOP_FOREGROUND_SERVICE', // 포그라운드 서비스 종료
  UPDATE_CUR_POSITION: 'UPDATE_CUR_POSITION', // 현재 위치 업데이트
  UPDATE_CUR_DEGREE: 'UPDATE_CUR_DEGREE', // 현재 각도 업데이트
  // deeplink
  OPEN_DEEPLINK: 'OPEN_DEEPLINK', // 네이버 딥링크 열기
  ONBOARD_PERMISSION: 'ONBOARD_PERMISSION', // 온보드에서 권한 요청,
  FIRST_LAUNCH: 'FIRST_LAUNCH', // 최초 실행
  VERSION_CHECK: 'VERSION_CHECK', // 버전 체크
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION', // 푸쉬 알림
} as const;
type NATIVE_MSG_ID = (typeof NATIVE_MSG_ID)[keyof typeof NATIVE_MSG_ID];

export default NATIVE_MSG_ID;

/**
 * 통신 데이터 인터페이스
 */
// 전화앱 열기
export interface IOpenPhoneAppMsg {
  phoneNumber: string;
}

// 사진 업로드
export interface IOpenPhotoUploadMsg {
  orderNo: string;
}
// access token
export interface ILoginMsg {
  accessToken: string;
}

export interface ILocationMsg {
  latitude: number;
  longitude: number;
}

export interface IDegreeMsg {
  degree: number;
}

export interface IWebviewReadyMsg {
  token: string;
  isFirst: boolean;
}

/**
 * 작업
 */
export interface IDeeplinkParams {
  type: 'naver' | 'kakao' | 'tmap';
  curLocation: ICoord;
  routeItem: any; //IRouteExcelData
}
