/**
 * @file: Login.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.27 ~
 * @description: 로그인 페이지
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '@hooks/useModal';
import { userInfoData } from '@data/userInfo';

import Button from '@components/atoms/Button';
import Input from '@components/molecules/Input';

export default function Login() {
  const navigate = useNavigate();
  const { showModal } = useModal();

  const [inputLoginInfo, setInputLoginInfo] = useState({
    email: '',
    password: '',
  });

  const onChangeLoginInput = (e: any) => {
    const { value, name } = e.target;

    setInputLoginInfo({
      ...inputLoginInfo,
      [name]: value,
    });
  };

  /** 로그인 버튼 클릭 */
  const onClickLoginBtn = () => {
    if (!inputLoginInfo.email || !inputLoginInfo.password) {
      showModal({
        title: '계정정보를 입력해주세요!',
        message: '로그인 정보가 필요해요',
      });
      return;
    }

    if (
      inputLoginInfo.email !== userInfoData.email ||
      inputLoginInfo.password !== userInfoData.password
    ) {
      showModal({
        title: '계정정보를 다시 확인해주세요!',
        message: '이메일 또는 비밀번호를 다시 확인해주세요',
      });
      return;
    }

    navigate('/main');
  };

  return (
    <div className="min-w-[360px] max-w-[400px] pb-8 px-4 mx-auto">
      <div className="py-20 text-center">
        <span className="text-3xl font-bold">루트플로우</span>
      </div>

      <div className="mb-12">
        <Input
          className="mb-4 py-2"
          name="email"
          placholder="이메일"
          value={inputLoginInfo.email}
          onChange={onChangeLoginInput}
        />
        <Input
          className="py-2"
          name="password"
          type="password"
          placholder="비밀번호"
          value={inputLoginInfo.password}
          onChange={onChangeLoginInput}
        />
      </div>

      <Button
        text="로그인"
        buttonClassName="text-white w-full"
        size="lg"
        gradation
        onClick={onClickLoginBtn}
      />
    </div>
  );
}
