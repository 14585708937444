import { BottomSheet, BottomSheetProps } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

export default function BottomSheetTemplate({
  children,
  header,
  ...rest
}: BottomSheetProps) {
  return (
    <BottomSheet
      className="relative z-[200]"
      header={
        <div className="dark:text-gray-07 font-bold text-left">{header}</div>
      }
      {...rest}
    >
      <div>{children}</div>
    </BottomSheet>
  );
}

// 바텀시트 리스트 공통 레이아웃
export const BottomSheetListLayout = ({ children }: any) => {
  return (
    <div className="m-4 flex flex-col items-start justify-start gap-4 mb-8">
      {children}
    </div>
  );
};
