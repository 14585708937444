/**
 * @file: Input.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.27 ~
 * @description:
 */

import classNames from 'classnames';
import { ChangeEvent } from 'react';

type Type = 'text' | 'number' | 'password';

interface IProps {
  type?: Type;
  placholder?: string;
  className?: string;
  inputClassName?: string;
  label?: string;
  name?: string;
  value?: string | number | any;
  defaultValue?: string | number;
  readOnly?: boolean;
  disabled?: boolean;
  border?: boolean;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

export default function Input({
  type = 'text',
  placholder,
  className,
  inputClassName,
  label,
  name,
  value,
  defaultValue,
  readOnly,
  disabled,
  border = true,
  maxLength,
  onChange,
  onClick,
}: IProps) {
  return (
    <div
      className={classNames(
        `${
          border ? 'border border-solid' : ''
        } rounded-lg px-2 w-full flex flex-col justify-center`,
        className,
      )}
    >
      {label && <label className="text-tertiary text-xs">{label}</label>}
      <input
        className={classNames(
          'w-full bg-inherit focus:outline-none py-1',
          inputClassName,
        )}
        type={type}
        name={name}
        placeholder={placholder}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
    </div>
  );
}
