import { atom } from 'recoil';

/**
 * 현재 내 위치 (native 기준)
 */
export const myCurPositionAtom = atom<{
  myLat: number; // 위도
  myLng: number; // 경도
  myDegree: number; // 이동방향
  enabled: boolean; // 위치 활성화 여부
}>({
  key: 'myCurPositionAtom',
  default: { myLat: 0, myLng: 0, myDegree: 0, enabled: false },
});
