/**
 * @file Modal.tsx
 * @author chad / chad@o2pluss.com
 * @description alert Modal
 */

import { useModal } from '@hooks/useModal';

import ModalTemplate from '@components/templates/ModalTemplate';
import Button from '@components/atoms/Button';

export interface IModalProps {
  type?: 'alert' | 'confirm';
  title?: string;
  message: string | React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
}

export default function Modal({
  type = 'alert',
  title,
  message,
  onOk,
  onCancel,
}: IModalProps) {
  const { hideModal } = useModal();

  /** ok 버튼 */
  const onClickOk = async () => {
    if (onOk) {
      const okPromise = async () => onOk();
      await okPromise();
    }
    hideModal();
  };

  /** 취소 버튼 */
  const onClickCancel = async () => {
    if (onCancel) {
      const cancelPromise = async () => onCancel();
      await cancelPromise();
    }
    hideModal();
  };

  return (
    <ModalTemplate isOpen className="w-[280px]">
      <div className="mb-6 text-center">
        <p className="font-bold mb-2">{title}</p>
        <span className="text-sm text-gray-700">{message}</span>
      </div>
      <div className="text-right mt-6 flex justify-between">
        {type === 'confirm' && (
          <Button
            buttonClassName="text-sm w-[48%]"
            text="취소"
            size="md"
            grayBorder
            onClick={onClickCancel}
          />
        )}
        <Button
          buttonClassName={`text-white text-sm bg-primary-01 ${
            type === 'confirm' ? 'w-[48%]' : 'w-full'
          }`}
          text="확인"
          size="md"
          onClick={onClickOk}
        />
      </div>
    </ModalTemplate>
  );
}
