/**
 * @file HeaderNavigation.tsx
 * @author chad / chad@o2pluss.com
 * @description 헤더 네비게이션 컴포넌트
 */

import { MY_ORDER } from '@constants/myOrder';

import Button from '@components/atoms/Button';
import { Dispatch, SetStateAction } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title?: string;
  isShowMapToggle?: boolean;
  myOrderToggle?: string;
  setMyOrderToggle?: Dispatch<SetStateAction<string>>;
}

export default function Navigation({
  title = '루트플로우',
  isShowMapToggle = false,
  myOrderToggle,
  setMyOrderToggle,
}: IProps) {
  const navigate = useNavigate();

  return (
    <div className="relative my-3 flex items-center">
      <GiHamburgerMenu className="cursor-pointer" size="24px" />
      <h6
        className="font-bold text-md mx-auto cursor-pointer"
        role="presentation"
        onClick={() => navigate('/main')}
      >
        {title}
      </h6>

      {isShowMapToggle && (
        <div className="absolute rounded-2xl right-0 bg-gray-100">
          <Button
            buttonClassName={`text-sm ${
              myOrderToggle === MY_ORDER.LIST
                ? 'bg-primary-01 text-white'
                : 'text-gray-500'
            }`}
            text={MY_ORDER.LIST}
            chip
            bold={false}
            onClick={() => setMyOrderToggle?.(MY_ORDER.LIST)}
          />
          <Button
            buttonClassName={`text-sm ${
              myOrderToggle === MY_ORDER.MAP
                ? 'bg-primary-01 text-white'
                : 'text-gray-500'
            }`}
            text={MY_ORDER.MAP}
            chip
            bold={false}
            onClick={() => setMyOrderToggle?.(MY_ORDER.MAP)}
          />
        </div>
      )}
      <span className="w-[24px]" />
    </div>
  );
}
