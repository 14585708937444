import { IReceiptRoute, IRouteExcelData } from '@data/receiptRouteInfo';
import { atom, useRecoilState, RecoilEnv } from 'recoil';

export const routeInfoAtom = atom<IReceiptRoute>({
  key: 'routeInfo',
  default: {
    id: 0,
    routeName: '',
    vehicleNumber: '',
    routeInfo: [],
  },
});

/** 메인페이지에서 선택한 경로 */
export const useRouteInfo = () => {
  const [routeInfo, setRouteInfo] =
    useRecoilState<IReceiptRoute>(routeInfoAtom);

  return {
    routeInfo,
    setRouteInfo,
  };
};

export const routeDetailAtom = atom<IRouteExcelData | null>({
  key: 'routeDetail',
  default: null,
});

/** 경로 상세 정보 */
export const useRouteDetail = () => {
  const [routeDetail, setRouteDetail] = useRecoilState<IRouteExcelData | null>(
    routeDetailAtom,
  );

  return {
    routeDetail,
    setRouteDetail,
  };
};

export const currentDeliveryAtom = atom<number>({
  key: 'currentDelivery',
  default: 1,
});

/** 현재 배송 주소 Index */
export const useCurrentDelivery = () => {
  const [currentDeliveryAddress, setCurrentDeliveryAddress] =
    useRecoilState<number>(currentDeliveryAtom);

  return {
    currentDeliveryAddress,
    setCurrentDeliveryAddress,
  };
};

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
