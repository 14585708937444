interface IUserInfo {
  id: number;
  email: string;
  password: string;
  name: string;
  phoneNumber: string | number;
  vehicleNumber?: string;
  rider_lat?: string | number;
  rider_lng?: string | number;
}

export const userInfoData: IUserInfo = {
  id: 1,
  email: 'chad@naver.com',
  password: 'o2pluss123',
  name: '이찬우',
  phoneNumber: '010-4853-9384',
  rider_lng: 126.530125,
  rider_lat: 33.5003515,
} as const;
