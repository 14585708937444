/**
 * @file MyOrder.tsx
 * @author chad / chad@o2pluss.com
 * @description 내 오더 페이지
 */

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MY_ORDER } from '@constants/myOrder';
import {
  useCurrentDelivery,
  useRouteDetail,
  useRouteInfo,
} from '@recoil/routeInfo';

// import { BsArrow90DegRight } from 'react-icons/bs';
import HeaderNavigation from '@components/molecules/HeaderNavigation';
import Button from '@components/atoms/Button';
import Card from '@components/atoms/Card';
import KakaoMap from '@components/atoms/KaKaoMap';
import { IRouteExcelData } from '@data/receiptRouteInfo';
import LocationDeeplinkButton from '@components/molecules/LocationDeepLinkBtn';

export default function MyOrder() {
  const navigate = useNavigate();
  const { routeInfo } = useRouteInfo();
  const { setRouteDetail } = useRouteDetail();
  const { currentDeliveryAddress } = useCurrentDelivery();

  const [myOrderToggle, setMyOrderToggle] = useState<string>(MY_ORDER.LIST);

  /** 상세보기 클릭 */
  const onClickDetailBtn = useCallback(
    (detail: IRouteExcelData) => {
      setRouteDetail(detail);
      navigate('/main/my-order/detail');
    },
    [navigate, setRouteDetail],
  );

  return (
    <>
      <HeaderNavigation
        title="내 오더"
        isShowMapToggle
        myOrderToggle={myOrderToggle}
        setMyOrderToggle={setMyOrderToggle}
      />

      {myOrderToggle === MY_ORDER.LIST ? (
        <div className="pt-4">
          <p className="mb-3 text-sm">
            총 <span>{routeInfo.routeInfo.length}</span> 건
          </p>

          {routeInfo.routeInfo.map((info, index) => {
            return (
              <div key={info.id} className="relative">
                <div
                  className={`
                    ml-3 pl-4 pb-3 border-l
                    ${
                      info.isDelivery || index === 0
                        ? 'border-solid'
                        : 'border-dashed'
                    }
                    ${
                      routeInfo.routeInfo.length === index + 1
                        ? 'border-white'
                        : 'border-gray-900'
                    }
                  `}
                >
                  <div
                    className={`absolute w-6 h-6 rounded-xl left-0 text-base flex items-center justify-center ${
                      info.isDelivery
                        ? 'bg-gray-500 text-white'
                        : !info.isDelivery &&
                            currentDeliveryAddress === index + 1
                          ? 'bg-primary-text text-white'
                          : 'bg-white border-solid border border-gray-900'
                    }`}
                  >
                    {index + 1}
                  </div>
                  <Card
                    cardClassName={`${
                      info.isDelivery ? 'border-gray-200' : 'border-gray-900'
                    }`}
                    border
                  >
                    <p className="mb-3">{info.recieve_address_1}</p>
                    <div className="flex items-center justify-end">
                      {info.isDelivery ? (
                        <Button
                          buttonClassName="bg-gray-200 text-white text-sm mr-2"
                          text="운송완료"
                        />
                      ) : (
                        <LocationDeeplinkButton routeItem={info} />
                      )}
                      <Button
                        buttonClassName="text-sm"
                        text="상세보기"
                        grayBorder
                        onClick={() => onClickDetailBtn(info)}
                      />
                    </div>
                  </Card>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            width: '100vw',
            marginLeft: 'calc(-50vw + 50%)',
            height: 'calc(100dvh - 50px)',
            marginBottom: 'calc(-50dvh + 50%)',
          }}
        >
          <KakaoMap routeInfo={routeInfo.routeInfo} />
        </div>
      )}
    </>
  );
}
