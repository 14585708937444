import { useState } from 'react';
import usePostMessage from '@hooks/usePostMessage';
import NATIVE_MSG_ID, { IDeeplinkParams } from '@constants/nativeMessageFormat';
import BottomSheetTemplate from '@components/atoms/BottomSheet';
import { useRecoilState } from 'recoil';
import { myCurPositionAtom } from '@recoil/map';
import kakaoMapIcon from '@assets/imgs/kakaomap.webp';
import naverMapIcon from '@assets/imgs/navermap.webp';
import tmapIcon from '@assets/imgs/tmap.webp';
import Button from '@components/atoms/Button';
import { BsArrow90DegRight } from 'react-icons/bs';

interface Props {
  routeItem: any;
}

export default function LocationDeeplinkButton({ routeItem }: Props) {
  const [curMyPosition, setCurMyPosition] = useRecoilState(myCurPositionAtom);
  const [open, setOpen] = useState(false);
  const { sendToNative } = usePostMessage({
    key: NATIVE_MSG_ID.OPEN_DEEPLINK,
  });

  const onClick = (type: IDeeplinkParams['type']) => {
    const data: IDeeplinkParams = {
      type,
      curLocation: {
        lat: curMyPosition.myLat,
        lng: curMyPosition.myLng,
      },
      routeItem,
    };
    console.log(data);
    sendToNative(data);
  };

  return (
    <>
      <Button
        buttonClassName="bg-state-info text-white text-sm mr-2"
        icon={<BsArrow90DegRight className="mr-1" />}
        text="길 안내"
        onClick={() => {
          setOpen(true);
          // onClick('naver');
        }}
      />
      <BottomSheetTemplate
        open={open}
        onDismiss={() => setOpen(false)}
        header="지도 앱 열기"
      >
        <div className="w-full flex justify-around items-center my-6 mb-8">
          <AppButton
            url={tmapIcon}
            title="티맵"
            onClick={() => onClick('tmap')}
          />
          <AppButton
            url={naverMapIcon}
            title="네이버 지도"
            onClick={() => onClick('naver')}
          />
          <AppButton
            url={kakaoMapIcon}
            title="카카오맵"
            onClick={() => onClick('kakao')}
          />
        </div>
      </BottomSheetTemplate>
    </>
  );
}

const AppButton = ({ url, title, onClick }: any) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-col justify-center items-center gap-2"
    >
      <img
        src={url}
        alt={title}
        className="w-12 h-12 rounded-xl object-cover"
      />
      <p>{title}</p>
    </button>
  );
};
