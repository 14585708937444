/**
 * @file HeaderBack
 * @author chad / chad@o2pluss.com
 * @description 경로 상세보기 헤더 컴포넌트
 */

import { useNavigate } from 'react-router-dom';

import { IoIosArrowBack } from 'react-icons/io';
import { useCallback } from 'react';
import Button from '@components/atoms/Button';

interface IProps {
  title: string;
  isDelivery?: boolean;
}

export default function HeaderBack({ title, isDelivery }: IProps) {
  const navigate = useNavigate();

  /** 뒤로가기 버튼 클릭 */
  const onClickBackBtn = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="relative my-3 flex items-center">
      <IoIosArrowBack
        className="cursor-pointer"
        size="24px"
        onClick={onClickBackBtn}
      />
      <h6 className="font-bold text-lg mx-auto">{title}</h6>

      {isDelivery && (
        <div className="absolute right-0">
          <Button
            buttonClassName="bg-gray-200 text-white text-sm"
            text="운송 완료"
          />
        </div>
      )}

      <span className="w-[24px]" />
    </div>
  );
}
